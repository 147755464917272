<template>
	<div class="feed" v-if="JSON.stringify(feedbackinfo) != '{}'">
		<!-- 导航栏 -->
		<!-- 导航栏 -->
		<van-nav-bar class="nav_bar_color" title="试乘试驾反馈" left-text="返回" left-arrow @click-left="onClickLeft" fixed
			z-index="11" :border="false" placeholder>
		</van-nav-bar>
		<!-- 头部 -->
		<div class="head">
			<!-- 导航栏占位 -->

			<div class="feed_concat">
				<div class="feed_concat_name">
					尊敬的{{feedbackinfo.name}}：
				</div>

				<div class="feed_concat_content">
					&nbsp;&nbsp;感谢您在{{getDateTime2(feedbackinfo.starttime).slice(5)}}参与了凯迪拉克{{feedbackinfo.model_name}}的试乘试驾体验，在本次体验中，您定制了{{feedbackinfo.score}}项专属试乘试驾体验项目，其中印象最为深刻与需要改善的项目体验是
				</div>



				<div class="feed_Score_top">

					<div class="feed_Score">
						我的评分
						<span class="feed_Score_name">
							我对本次试乘试驾的评价
						</span>
					</div>
					<div v-for="v, index in feedbackinfo.feedback" :key="index" class="">
						<div class="feed_Score_title">
							{{ v.content }}
						</div>
						<van-rate
							v-model="form.feedback[index]"
							:size="40"
							color="#ffd21e"
							void-icon="star"
							void-color="#333333"
							@change="onChange($event, index)"
						/>
						<!-- <uni-rate allowHalf size="40" color="#333333" v-model="form.feedback[index]"
							@change="onChange($event, index)" /> -->
					</div>

				</div>
				<div class="feed_line">

				</div>

				<div class="feed_recommend">
					<div class="feed_recommend_title">
						是否愿意向朋友推荐凯迪拉克的产品
					</div>
					<div class="feed_recommend_list">
						<div @click="upcolor(1)" :class="{ 'active-color': form.if_recommend == 1 }" class="feed_recommend_Single">
							非常乐意
						</div>
						<div @click="upcolor(2)" :class="{ 'active-color': form.if_recommend == 2 }" class="feed_recommend_Single">
							看情况
						</div>
						<div @click="upcolor(3)" :class="{ 'active-color': form.if_recommend == 3 }" class="feed_recommend_Single">
							再说吧
						</div>
						<div @click="upcolor(4)" :class="{ 'active-color': form.if_recommend == 4 }" class="feed_recommend_Single">
							不愿意
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="user_footer">
			<div class="" @click="save">
				提交反馈单
			</div>
		</div>
	</div>
</template>

<script>
export default {

	data() {
		return {
			feedbackinfo: {},
			form: {
				if_recommend: '',
				store_id: 0,
				number: '',
				feedback: [],
				feedback_content_ids: '',
				score: 10,
			}
		}
	},
	mounted() {
		console.log('12121',this.$route.query.number)
		this.form.number = this.$route.query.number
		this.getfeedbackinfo()
	},
	methods: {
		// 时间格式化
		getDateTime2(timestamp) {
			const date = new Date(timestamp * 1000)
			const Y = date.getFullYear() + '年'
			const M = (date.getMonth() + 1 < 10 ? (date.getMonth() + 1) : date.getMonth() + 1) + '月'
			const D = (date.getDate() < 10 ? date.getDate() : date.getDate()) + '日'
			return Y + M + D
		},
		async save() {
			if (!this.form.feedback.every(item => item > 0)) return this.$toast.fail('请选择评分')
			if (!this.form.if_recommend) return this.$toast.fail('请选择推荐')
			this.form.feedback = this.form.feedback.map(item => parseFloat(item) * 2)
			this.form.feedback = this.form.feedback.join(',')
			const { data: res } = await this.$http.post('/api/scsj/edit_feedback_report_1_7', this.form)
			if (res.code == 1) {
				this.$toast.success('反馈成功')
				this.$router.replace({ // 跳转到tabbar
					path: '/share/drive-report-share-super?number=' + this.form.number
				})
			} else {
				this.$toast.fail(res.mag)
			}
		},
		// 满意选择
		upcolor(e) {
			this.form.if_recommend = e
			console.log(e, this.form.if_recommend)
		},
		// 评分
		onChange(e, index) {
			console.log(e, index)
			this.form.feedback[index] = e
		},
		// 获取详情
		async getfeedbackinfo() {
			const { data: res } = await this.$http.post('/api/scsj/get_feedback_report_1_7', { number: this.form.number })
			if (res.code == 1) {
				if (res.data.res.if_recommend != null) {
					return this.$router.replace({ // 跳转到tabbar
						path: '/share/drive-report-share-super?number=' + this.form.number
					})
				}
				this.feedbackinfo = res.data.res
				this.form.feedback = this.feedbackinfo.feedback.map(item => 0)
				this.form.feedback_content_ids = this.feedbackinfo.feedback.map(item => item.id).join(',')
			}
		},
	}

}
</script>

<style lang="scss" scoped>
.feed_concat {
	.feed_concat_name {
		background: #000000;
		padding: 40px 40px 0;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		line-height: 36px;
	}

	.feed_concat_content {
		background: #000000;
		padding: 30px 40px;
		font-size: 32px;
		font-family: HYQiHeiY3-45;
		font-weight: normal;
		color: #E7E7E7;
		line-height: 48px;
	}

	.feed_Score {
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
		padding: 34px 0;
		border-bottom: 1px solid #333333;

		.feed_Score_name {
			padding: 40px 0;
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #A1A1A1;
			margin-left: 36px;
		}
	}

	.feed_Score_title {
		margin-top: 50px;
		padding: 32px 0;
		font-size: 32px;
		line-height: 58px;
		font-family: Regular;
		font-weight: 400;
		color: #FFFFFF;
	}

	.feed_line {
		margin-top: 50px;
		height: 1px;
		background: #333333;
	}

	.feed_recommend {
		padding: 48px 40px;

		.feed_recommend_title {
			font-size: 32px;
			font-family: HYQiHeiY3-45;
			font-weight: normal;
			color: #FFFFFF;
			line-height: 32px;
		}

		.feed_recommend_list {
			margin-top: 48px;
			display: flex;
			justify-content: space-between;

			.feed_recommend_Single {
				width: 148px;
				height: 100px;
				background: #333333;
				text-align: center;
				line-height: 100px;
				font-size: 28px;
				font-family: HYQiHeiY3-45;
				font-weight: normal;
				color: #FFFFFF;
			}
		}
	}
}

.user_footer {
	height: 132px;
	background: #000000;
	text-align: center;
	padding: 20px;
	box-sizing: border-box;
	div {
		width: 670px;
		height: 92px;
		background: #FA0037;
		text-align: center;
		margin: 0 auto 0;
		line-height: 92px;
		font-size: 36px;
		font-family: HYQiHeiY3-55;
		font-weight: normal;
		color: #FFFFFF;
	}
}

.feed_Score_top {
	// background: #222222;
	padding: 0 40px;
}

.active-color {
	background-color: #f3c845 !important;
}
</style>